const {getPlaceByUid} = require('@/init/api')
const {NEW_ORDERS_TIMEOUT} = require('@/consts')

class OrdersStore {
  constructor({ DB, userUid, axios, currencies, $API_URL, uuid, bus }) {
    /**
     * utils
     */
    // firestore
    this.DB = DB
    // XHRHTTP client
    this.$axios = axios
    // api prefix
    this.$API_URL = $API_URL
    // generate uids
    this.uuid = uuid
    this.$bus = bus
    this.hasStorage = typeof localStorage !== 'undefined'

    /**
     * data
     */
    this.currencies = currencies
    this.unsubs = {
      checkins: null,
      invoices: null,
      checkinItems: null,
      unpayedInvoices: null,
      paymentSources: null,
    }
    this.checkins = []
    this.invoices = []
    this.orderItems = []
    this.orderItemsLen = 0
    this.deliveryFlow = false
    this.takeAway = false
    this.currencySymbol = ''
    this.totalAmount = 0
    this.serviceFee = 0
    this.apiBusy = false
    this.checkinItems = []
    this.orderComments = {}
    this.dontAskForComments = false
    this.newOrdersSubscribeTimer = null

    this.deliveryZone = {
      min_price: '',
      price: '',
      price_to_free: '',
      radius_in_meters: 0,
      place_uid: '',
    }

    this.calculatedUnpaid = {
      additional_payments: {},
      sub_total: 0,
      total: 0,
      error: '',
    }
    this.calculated = {
      additional_payments: {},
      sub_total: 0,
      total: 0,
      error: '',
    }
    this.unpayedInvoices = []
    this.paymentSources = []

    // init
    this.changeUserUid(userUid)
    this.initOrderItems()
  }

  setInCache(key, value) {
    if (this.hasStorage) {
      localStorage.setItem(key, value)
    }
  }

  /**
   * change user Hook
   */
  changeUserUid(userUid) {
    this.userUid = userUid
    if (!userUid) return
    this.watchInvoices()
    this.watchCheckinItems()
    this.initCheckins()
    this.setCalculate()
    this.watchUnpayedInvoices(userUid)
    this.watchPaymentSources(userUid)
  }

  changeDeliveryFlow(value = false, takeAway = false) {
    this.deliveryFlow = value
    this.takeAway = takeAway
    if (takeAway) {
      this.updateDeliveryZone({}, true)
    }
    this.setInCache('user_order_delivery_flow', value)
    if (typeof localStorage === 'undefined') return
  }
  /**
   * local user orders manipulations
   */

  initOrderItems() {
    if (typeof localStorage === 'undefined') return
    let cached = localStorage.getItem('user_order')
    if (cached) {
      try {
        cached = JSON.parse(cached)
      } catch (e) {
        console.log('can not parse cached order')
        cached = []
      }
      this.orderItems.push(...cached)
      this.orderItemsLen = this.orderItems.length
    }
    const currencySymbol = localStorage.getItem('user_order_cur_symb')
    if (currencySymbol) {
      this.currencySymbol = currencySymbol
    }
    const deliveryFlow =
      localStorage.getItem('user_order_delivery_flow') || false
    this.deliveryFlow = deliveryFlow ? deliveryFlow !== 'false' : false
    let orderComments = localStorage.getItem('orderComments')
    if (orderComments) {
      try {
        orderComments = JSON.parse(orderComments)
      } catch (e) {
        console.log('can not parse comments')
        orderComments = {}
      }
      this.orderComments = orderComments
    }
    const dontAskForComments = localStorage.getItem('dontAskForComments')
    if (dontAskForComments) {
      this.dontAskForComments = true
    }
  }

  checkSimilarOrderItemType(order = [], deliveryFlow = false) {
    return order.some((orderItem) => orderItem.delivery !== deliveryFlow)
  }

  addToOrder({ dish, currencySymbol, selectedModifiers = [], fromPlaceUid }) {
    const { orderItems: order, deliveryFlow, unpayedInvoices } = this
    const orderLen = order.length

    if (this.checkSimilarOrderItemType(order, deliveryFlow)) {
      return { error: 'from_different_flows', deliveryFlow }
    }

    const checkin = this.checkins?.[0]
    if (checkin) {
      const isDeliveryCheckin =
        checkin.type === 'take_away' || checkin.type === 'delivery'
      if ((!deliveryFlow && isDeliveryCheckin) || deliveryFlow)
        return 'has_checkin'

      const place_uid = checkin.place_uid
      if (place_uid !== dish.place_uid || place_uid !== fromPlaceUid) {
        const place = window.PS.data.find((pl) => pl.uid === place_uid)
        const related_places_uids = place.related_places_uids || []
        if (
          place_uid !== fromPlaceUid ||
          !~related_places_uids.indexOf(dish.place_uid)
        ) {
          return { error: 'checkin_in_another_place', place }
        }
      }
    }

    if (orderLen) {
      const place_uid = order?.[0]?.place_uid
      if (place_uid !== dish.place_uid) {
        order.splice(0, orderLen)
      }
    }
    const modifiers = dish.modifiers || []
    const requiredModifiers = modifiers.filter((mdf) => !mdf.is_optional)
    const requiredModifiersLen = requiredModifiers.length
    if (requiredModifiersLen) {
      const selectedRequired = requiredModifiers.reduce((memo, mdf) => {
        const modifier_uid = mdf.uid
        const selectedModification = selectedModifiers.find(
          (selMdf) => selMdf.modifier_uid === modifier_uid
        )
        if (selectedModification) {
          memo.push(selectedModification)
        }
        return memo
      }, [])
      if (selectedRequired.length !== requiredModifiersLen) {
        return 'modificators_required'
      }
    }
    // custom props for temp selected modificators
    dish.selectedModifiers = selectedModifiers
    dish.showModifiers = false
    dish.comment = ''
    dish.delivery = deliveryFlow
    dish.fromPlaceUid = fromPlaceUid

    const _ordLen = order.push(dish)
    this.orderItemsLen = order.length
    this.currencySymbol = currencySymbol
    this.setInCache('user_order_cur_symb', currencySymbol)
    this.setInCache('user_order', JSON.stringify(order))
    this.setCalculate()
    return _ordLen - 1
  }

  addComment({ index, comment }) {
    if (isNaN(index) || !comment) return
    const dish = this.orderItems[index]
    if (!dish) return
    dish.comment = comment
    this.setInCache('user_order', JSON.stringify(this.orderItems))
  }

  removeComment(index) {
    if (isNaN(index)) return
    const dish = this.orderItems[index]
    if (!dish) return
    dish.comment = ''
    this.setInCache('user_order', JSON.stringify(this.orderItems))
  }

  removeFromOrder(index) {
    const order = this.orderItems
    if (index === -10) {
      order.splice(0, order.length)
      // set to local storage
      this.setInCache('user_order', JSON.stringify(order))
    } else if (order[index]) {
      order.splice(index, 1)
      this.setInCache('user_order', JSON.stringify(order))
    }
    this.orderItemsLen = order.length
    this.setCalculate()
  }

  /**
   *
   * @returns calculated order on server
   */
  calculateOdrer(checkin_uid = '', address = '') {
    return new Promise((resolve) => {
      this.apiBusy = true
      const order = this.orderItems
      const deliveryFlow = this.deliveryFlow
      const place_uid = order?.[0]?.fromPlaceUid || order?.[0]?.place_uid
      if (!order.length || !place_uid) {
        this.apiBusy = false
        resolve(false)
        return
      }
      const sendData = {
        items: order.map((item) => {
          const _item = {
            uid: this.uuid(),
            item_uid: item.uid,
            modifications: item.selectedModifiers,
          }
          return _item
        }),
        place_uid,
      }
      if (deliveryFlow) {
        sendData.take_away = address ? false : true
      }
      if (address) {
        sendData.delivery = {
          uid: this.uuid(),
          address,
        }
      }
      if (checkin_uid) {
        sendData.checkin_uid = checkin_uid
      }
      this.$axios
        .post(this.$API_URL('/orders/calculate'), sendData)
        .then((resp) => {
          this.apiBusy = false
          resolve(resp?.data?.payload)
        })
        .catch((err) => {
          const eStatus = err?.response?.status
          this.apiBusy = false
          console.log('err', err)

          if (eStatus === 401) {
            resolve(
              this.$bus.updateToken(this.calculateOdrer.bind(this), checkin_uid)
            )
          } else {
            resolve(false)
          }
        })
    })
  }

  setCalculate(address = '') {
    this.calculateOdrer('', address)
      .then((payload) => {
        if (!payload) {
          this.totalAmount = 0
          this.serviceFee = 0
          this.updateUnpaid({ error: 'error_code' }, true, 'calculated')
        } else {
          this.updateUnpaid(payload, false, 'calculated')
          this.totalAmount = Number(payload?.total || 0)
          this.serviceFee = Number(
            payload?.additional_payments?.service_fee || 0
          )
        }
      })
      .catch((error) => {
        console.log('erro', error)
      })
  }

  /**
   *
   * @param {*} newItems
   * @returns arrayOf Upgrated of Items
   */
  async upgradeCheckins(newItems = []) {
    const currencies = this.currencies
    for (let i = 0; i < newItems.length; i++) {
      const checkin = newItems[i]
      const items = (
        await this.DB.db
          .collection('order_items')
          .where('checkin_uid', '==', checkin?.uid)
          .get()
      ).docs.map((doc) => doc.data())
      const [place] = (
        await this.DB.db
          .collection('places')
          .where('uid', '==', checkin?.place_uid)
          .get()
      ).docs.map((doc) => doc.data())
      if (place) {
        newItems[i].currency = place.currency
        newItems[i].currencySymbol =
          currencies[place.currency] || currencies.USD
      }
      newItems[i].items = items
    }

    return newItems
  }

  addedInvoiceHandler(entity, collection) {
    if (entity.status === 'needs_user_approval' && entity.measure_amount) {
      this.$bus.$emit(
        'GOM/checkinItem/priceChanged',
        entity,
        this.currencySymbol
      )
    }
    this[collection].push(entity)
  }

  modifiedInvoiceHandler(entity, collection) {
    const { uid, status, measure_amount } = entity
    const needsUserApproval = status === 'needs_user_approval'
    let oldMeasureAmount = null
    const newMeasureAmount = measure_amount || null
    const oldEntity = this[collection].find((i) => i.uid === uid)
    if (oldEntity) {
      if (entity.measure_amount) {
        oldMeasureAmount = oldEntity.measure_amount
      }
      Object.assign(oldEntity, entity)
    }
    if (needsUserApproval && oldMeasureAmount !== newMeasureAmount) {
      this.$bus.$emit(
        'GOM/checkinItem/priceChanged',
        entity,
        this.currencySymbol
      )
    }
  }

  removedInvoiceHandler({ uid }, collection) {
    const index = this[collection].findIndex((i) => i.uid === uid)
    if (~index) {
      this[collection].splice(index, 1)
    }
  }

  watchInvoices(checkin) {
    this.invoices.length = 0
    if (typeof this.unsubs.invoices === 'function') {
      this.unsubs.invoices()
    }
    if (checkin) {
      const checkinUid = checkin.uid
      this.unsubs.invoices = this.DB.db
        .collection('invoices')
        .where('checkin_uid', '==', checkinUid)
        .onSnapshot((snapshot) => {
          snapshot.docChanges().forEach((change) => {
            const invoice = change.doc.data()
            /**
             * type are
             * added | modified | removed
             */
            const handlerName = change.type + 'InvoiceHandler'
            if (typeof this[handlerName] === 'function') {
              this[handlerName](invoice, 'invoices')
            }
          })
        })
    }
  }

  async watchCheckinItems(checkin) {
    this.checkinItems.splice(0, this.checkinItems.length)
    if (typeof this.unsubs.checkinItems === 'function') {
      this.unsubs.checkinItems()
    }
    if (!checkin) {
      return
    }

    const onSnapshotCallback = (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        const item = change.doc.data()
        /**
         * type are
         * added | modified | removed
         */
        const handlerName = change.type + 'InvoiceHandler'
        if (typeof this[handlerName] === 'function') {
          this[handlerName](item, 'checkinItems')
        }
      })
    }

    const place = window.PS.getByUid(checkin.place_uid) || await getPlaceByUid(checkin.place_uid)
    this.checkins[0]['order_processing_type'] = place.order_processing_type;
    
    if (place.order_processing_type === false) {
      this.unsubs.checkinItems = () => {
        clearTimeout(this.newOrdersSubscribeTimer)
      }
      this.newOrdersSubscribe(this.checkins[0]);
    } else {
      this.unsubs.checkinItems = this.DB.db
        .collection('order_items')
        .where('checkin_uid', '==', checkin.uid)
        .onSnapshot(onSnapshotCallback)
    }
  }

  async newOrdersSubscribe(checkin) {
    try {
      clearTimeout(this.newOrdersSubscribeTimer)
      const resp = await this.getItemsByTable(checkin.place_uid, checkin.object_uid);
      this.checkinItems.splice(0, this.checkinItems.length, ...(resp || []))
    } catch (ex) {
      console.error(ex);
    } finally {
      this.newOrdersSubscribeTimer = setTimeout(() => {
        this.newOrdersSubscribe(checkin)
      }, NEW_ORDERS_TIMEOUT)
    }
  } 

  watchPaymentSources(userUid) {
    this.paymentSources.splice(0, this.paymentSources.length)
    if (typeof this.unsubs.paymentSources === 'function') {
      this.unsubs.paymentSources()
    }
    if (userUid) {
      this.unsubs.paymentSources = this.DB.db
        .collection('payment_sources')
        .where('user_uid', '==', userUid)
        .onSnapshot((snapshot) => {
          snapshot.docChanges().forEach((change) => {
            const item = change.doc.data()
            const handlerName = change.type + 'InvoiceHandler'
            if (typeof this[handlerName] === 'function') {
              this[handlerName](item, 'paymentSources')
            }
          })
        })
    }
  }

  /**
   * init subscribe on user checkins
   */
  async initCheckins() {
    this.checkins.splice(0, this.checkins.length)
    const checkinsUnsub = this.unsubs.checkins
    if (checkinsUnsub) {
      await checkinsUnsub()
    }

    /**
     *
     * manipulate with checkins
     */
    const updateCheckins = (newCheckins = [], oldCheckins = []) => {
      const updateTypes = ['number', 'string', 'boolean']
      newCheckins.forEach((checkin) => {
        const uid = checkin.uid
        const item = oldCheckins.find((ch) => ch.uid === uid)
        if (item) {
          for (const key in checkin) {
            const newValue = checkin[key]
            const oldValue = item[key]
            if (
              oldValue !== newValue &&
              ~updateTypes.indexOf(typeof newValue)
            ) {
              item[key] = newValue
            }
          }
        }
      })
    }

    const removeCheckins = (checkins = []) => {
      const stated = this.checkins
      checkins.forEach((checkin) => {
        const uid = checkin.uid
        const index = stated.findIndex((ch) => ch.uid === uid)
        if (~index) {
          stated.splice(index, 1)
        }
      })
      this.watchInvoices(this.checkins[0])
      this.watchCheckinItems(this.checkins[0])
    }

    const addCheckins = (checkins = []) => {
      this.checkins.push(...checkins)
      this.watchInvoices(this.checkins[0])
      this.watchCheckinItems(this.checkins[0])
    }

    const _timer = (seconds) => {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve()
        }, seconds)
      })
    }

    this.unsubs.checkins = this.DB.db
      .collection('checkins')
      .where('user_uid', '==', this.userUid)
      .where('status', 'in', ['deferred', 'approved'])
      .onSnapshot(async (snapshot) => {
        const [newItems, updates, removed] = [[], [], []]

        snapshot.docChanges().forEach((change) => {
          const _data = change.doc.data()
          if (change.type === 'added') {
            newItems.push(_data)
          }
          if (change.type === 'modified') {
            updates.push(_data)
          }
          if (change.type === 'removed') {
            removed.push(_data)
          }
        })
        if (updates.length) {
          const _checkins = this.checkins
          const items = await this.upgradeCheckins(updates)
          updateCheckins(items, _checkins)
        }
        if (removed.length) {
          removeCheckins(removed)
        }
        if (newItems.length) {
          // await _timer(1000)
          const items = await this.upgradeCheckins(newItems)
          addCheckins(items)
        }
      })
  }

  async watchUnpayedInvoices(userUid) {
    this.unpayedInvoices.splice(0, this.unpayedInvoices.length)
    const unpayedInvoicesUnsub = this.unsubs.unpayedInvoices
    if (unpayedInvoicesUnsub) {
      await unpayedInvoicesUnsub()
    }

    this.unsubs.unpayedInvoices = this.DB.db
      .collection('invoices')
      .where('user_uid', '==', userUid)
      .where('left_unpaid', '==', true)
      .onSnapshot(async (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          const _data = change.doc.data()
          if (change.type === 'added') {
            this.unpayedInvoices.push(_data)
          }
          if (change.type === 'modified') {
            const index = this.unpayedInvoices.findIndex(
              (invoice) => invoice.uid === _data.uid
            )
            if (~index) {
              Object.assign(this.unpayedInvoices[index], _data)
            }
          }
          if (change.type === 'removed') {
            const index = this.unpayedInvoices.findIndex(
              (invoice) => invoice.uid === _data.uid
            )
            if (~index) {
              this.unpayedInvoices.splice(index, 1)
            }
          }
        })
      })
  }

  async checkDeliveryZone(address, place_uid) {
    try {
      const {
        data: { status, payload, error_code },
      } = await this.$axios.post(this.$API_URL('/orders/deliveryZone'), {
        address,
        place_uid,
      })
      if (status === 'SUCCESS' && payload) {
        return { success: true, data: payload?.zone }
      }
      return { success: false, error_code }
    } catch (err) {
      const eStatus = err?.response?.status
      if (eStatus === 401) {
        return this.$bus.updateToken(
          this.checkDeliveryZone.bind(this),
          address,
          place_uid
        )
      } else {
        return { success: false, error_code: '' }
      }
    }
  }

  setNumber(val, fallback = 0) {
    if (isNaN(val)) return fallback
    return Number(val)
  }

  updateDeliveryZone(
    {
      min_price = '',
      price = '',
      price_to_free = '',
      radius_in_meters = 0,
      place_uid = '',
    },
    clear = false
  ) {
    if (clear) {
      min_price = 0
      price = 0
      price_to_free = 0
      radius_in_meters = 0
      place_uid = ''
    } else {
      min_price = this.setNumber(min_price)
      price = this.setNumber(price)
      price_to_free = this.setNumber(price_to_free)
    }
    this.deliveryZone.place_uid = place_uid
    this.deliveryZone.min_price = min_price
    this.deliveryZone.price = price
    this.deliveryZone.price_to_free = price_to_free
    this.deliveryZone.radius_in_meters = radius_in_meters
  }

  callWaiter(ask_for_bill = false) {
    return new Promise((resolve) => {
      const checkin = this.checkins[0]
      if (!checkin) {
        resolve({ success: false, error_code: '' })
        return
      }
      const { object_uid, place_uid } = checkin
      if (!(object_uid && place_uid)) {
        resolve({ success: false, error_code: '' })
      } else {
        this.$axios
          .post(this.$API_URL('/waiters/call'), {
            call: {
              ask_for_bill,
              object_uid,
              place_uid,
              uid: this.uuid(),
              user_uid: this.userUid,
            },
          })
          .then(({ data: { status, error_code } }) => {
            if (status === 'SUCCESS') {
              resolve({ success: true })
            } else {
              resolve({ success: false, error_code })
            }
          })
          .catch((err) => {
            const eStatus = err?.response?.status

            if (eStatus === 401) {
              this.$bus.updateToken(
                resolve(
                  this.callWaiter.bind(this),
                  ask_for_bill,
                  object_uid,
                  place_uid
                )
              )
            } else {
              resolve({ success: false, error_code: '' })
            }
          })
      }
    })
  }

  updateUnpaid(
    { additional_payments = {}, total = '', sub_total = '', error = '' },
    clear = false,
    assignPath = 'calculatedUnpaid'
  ) {
    if (clear) {
      additional_payments = {}
      total = 0
      sub_total = 0
      error = error || ''
    } else {
      total = this.setNumber(total)
      sub_total = this.setNumber(sub_total)
      Object.keys(additional_payments).forEach((additional_payment) => {
        additional_payments[additional_payment] = this.setNumber(
          additional_payments[additional_payment]
        )
      })
    }
    Object.assign(this[assignPath], {
      additional_payments,
      total,
      sub_total,
      error,
    })
  }

  calculateUnpaid() {
    return new Promise((resolve) => {
      const checkin = this.checkins[0]
      if (!checkin) {
        return resolve()
      }
      this.$axios
        .post(this.$API_URL('/orders/calculateUnpaid'), {
          checkin_uid: checkin.uid,
          place_uid: checkin.place_uid,
        })
        .then(({ data: { payload, error_code } }) => {
          if (!error_code) {
            this.updateUnpaid(payload)
          } else {
            this.updateUnpaid({ error: error_code }, true)
          }
        })
        .catch((error) => {
          this.updateUnpaid({ error }, true)
        })
    })
  }

  async initIikoByTable() {
    const checkin = this.checkins[0]
    if (!checkin?.uid) {
      throw Error('initIikoByTable: Have no checkin')
    }

    await this.HTTP('post', this.$API_URL('/integration/iiko/initByTable'), {
        checkin_uid: checkin.uid,
        object_uid: checkin.object_uid,
        place_uid: checkin.place_uid,
        user_uid: checkin.user_uid,
      })
  }

  async retrieveOrdersByTables() {
    const checkin = this.checkins[0]
    if (!checkin?.uid) {
      throw Error('retrieveOrdersByTables: Have no checkin')
    } else {
      await this.HTTP('post', this.$API_URL('/integration/iiko/retrieve-orders-by-tables'), {
          object_uid: checkin.object_uid,
          user_uid: checkin.user_uid,
          place_uid: checkin.place_uid,
          table_uids: [checkin.object_uid],
        })
    }
  }

  async getItemsByTable(
    place_uid,
    object_uid,
  ) {
    if (!(place_uid && object_uid)) {
      throw Error('retrieveOrdersByTables: Have no checkin')
    } else {
      const {payload} = await this.HTTP('post', this.$API_URL('/orders/getItemsByTable'), {
        place_uid,
        object_uid,
      })

      return payload
    }
  }

  async sleep(timer = 1000) {
    await new Promise((resolve) => setTimeout(resolve, timer))
  }

  async setDevice(place_uid) {
    if (!this.userUid) {
      await this.sleep(500)
      return this.setDevice(place_uid)
    }
    let answer = false
    try {
      await await this.HTTP('post', this.$API_URL('/users/setDevice'), {
        place_uid,
        user_uid: this.userUid,
        device: {
          device_uid: ' ',
        },
      })
      answer = true
    } catch (e) {
      console.log(e)
    }
    return answer
  }

  async getUserEmail() {
    const ref = this.DB.db.collection('users_private').doc(this.userUid)
    const doc = await ref.get()
    if (!doc.exists) {
      console.log('No such document!')
      return ''
    }
    return doc.data()?.mail || ''
  }

  async HTTP(type = 'get', url, data) {
    try {
      const response = await this.$axios[type](url, data)
      return response.data
    } catch (error) {
      const eStatus = error?.response?.status;
      if (eStatus === 401) {
          return await this.$bus.updateToken(
              this.HTTP.bind(this),
              type,
              url,
              data
          )
      } else {
        Promise.reject(error)
      }
    }
  }
}

module.exports = OrdersStore
